import { useEffect, useState } from 'react';

import { getPrice } from '../utils/priceService';

const usePrice = () => {
	const [info, setInfo] = useState(null);
	const [treatment, setTreatment] = useState(null);
	const [status, setStatus] = useState(null);

	const getPriceData = async () => {
		setStatus('loading');
		try {
			const res = await getPrice();
			setInfo(res.info)
			setTreatment(res.item)
		} catch (error) {
			setStatus('error');
		} finally {
			setStatus('success');
		}
	};

	useEffect(() => {
		getPriceData()
	}, []);

	return {
		info,
		status,
		treatment
	}
};

export default usePrice;