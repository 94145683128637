import BlockContent from '@sanity/block-content-to-react';

import Card from '../components/Card';
import usePrice from '../hooks/usePrice';

const About = () => {
	const { info, treatment } = usePrice();

	return (
		<>
			<Card>
				<BlockContent blocks={info || []} />
				{treatment?.map((item) => (
					<p className='treatment'key={item._key}>
						<span className='title'>{item.treatment}</span>
						<span className='amount'>
							<span className='fixed'>{item.fixedprice.fixed_price === false ? 'Fra kr ' : null}</span>
							<span className='price'>{item.price},-</span>
						</span>
					</p>
				))}
			</Card>
		</>
	);
};

export default About;