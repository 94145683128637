import { useEffect, useState } from 'react';

import { getEmployee } from '../utils/employeeService';

const useHours = () => {
	const [employee, setEmployee] = useState(null);
	const [status, setStatus] = useState(null);

	const getEmployeeData = async () => {
    setStatus('loading');
      try {
        const res = await getEmployee();
        setEmployee(res)
      } catch (error) {
        setStatus('error');
      } finally {
				setStatus('success');
			}
    };

	useEffect(() => {
		getEmployeeData()
	}, []);

	return {
		employee,
		status
	}
};

export default useHours;