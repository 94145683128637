import BlockContent from '@sanity/block-content-to-react';

import useAbout from '../hooks/useAbout';
import Card from '../components/Card';

const About = () => {
	const {about} = useAbout();

	return (
		<>
			<Card>
				<BlockContent blocks={about || []} />
			</Card>
		</>
	);
};

export default About;