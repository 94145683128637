import styled from 'styled-components';

const FooterBar = styled.footer`
	align-items: flex-end;
	display: flex;
	height: 150px;
	justify-content: center;
	margin-top: 40px;
	padding-bottom: 16px;
	width: 100%;
`;
const Footer = () => {
	return (
		<>
			<FooterBar>
				<p>&copy; Tannlegene Framnes - 2022</p>
			</FooterBar>
		</>
	);
};

export default Footer;