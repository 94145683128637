import styled from 'styled-components';

import CallRoundedIcon from '@mui/icons-material/CallRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import MailRoundedIcon from '@mui/icons-material/MailRounded'

import { Colors } from '../constants/Colors';
import useBusiness from '../hooks/useBusiness';

const Contact = styled.section`
	align-items: center;
	color: ${Colors.primary};
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media (max-width: 678px) {
		margin-top: 40px;
  }
`

const Details = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const Info = styled.a`
	color: ${Colors.primary};
	font-size: 24px;
	margin-left: 4px;
	text-decoration: none;
`
const Address = styled.a`
	color: ${Colors.primary};
	font-size: 14px;
	margin-left: 4px;
	text-decoration: none;
`;

const Logo = styled.img`
	align-items: center;
	width: 305px;
	justify-content: center;
`

const Main = styled.header`
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
	padding-top: 40px;
	@media (max-width: 678px) {
    flex-direction: column;
		align-items: center;
  }
`

const Content = styled.section`
	align-items: center;
	display: flex;
	justify-content: flex-end;
	width: 305px;
	@media (max-width: 678px) {
		justify-content: center;
		width: 100%;
  }
`

const Icon = styled.div`
	align-items: center;
	justify-content: center;
`

const Spacer = styled.div`
	margin-top: 10px;
	@media (max-width: 678px) {
		margin-top: 20px;
  }
`;

const Header = () => {
	const { data } = useBusiness();

	return (
		<>
			<Main>
				<Logo src={data?.logo} alt='Tannlegene Framnes logo'/>
				<Contact>
					<Content>
						<Icon>
							<CallRoundedIcon />
						</Icon>
						<Details>
							<Info href={`callto:${data?.phone}`}>
								{data?.phone}
							</Info>
						</Details>
					</Content>
					<Spacer />
					<Content>
						<Icon>
							<MailRoundedIcon />
						</Icon>
						<Details>
							<Address
								href={`mailto:${data?.mail}`}
							>
								{data?.mail}
							</Address>
						</Details>
					</Content>
					<Spacer />
					<Content>
						<Icon>
							<LocationOnRoundedIcon />
						</Icon>
						<Details>
							<Address
								href={'https://www.google.com/maps/place/Nordre+S%C3%A6trevei+4,+3475+S%C3%A6tre/@59.6820621,10.5255589,18z/data=!3m1!4b1!4m5!3m4!1s0x4641473ae242e45f:0x771964514e67bd59!8m2!3d59.6820621!4d10.5266559'}
								target='_blank'
							>
								{data?.address?.street}, {data?.address?.zip} {data?.address?.area}
							</Address>
						</Details>
					</Content>
				</Contact>
			</Main>
		</>
	);
};

export default Header;