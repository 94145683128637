import { useEffect, useState } from 'react';

import { getBusiness } from '../utils/businessService';

const useBusiness = () => {
	const [data, setData] = useState(null);
	const [status, setStatus] = useState(null);

	const getBusinessData = async () => {
		setStatus('loading');
		try {
			const res = await getBusiness();
			setData(res)
		} catch (error) {
			setStatus('error');
		} finally {
			setStatus('success');
		}
	};

	useEffect(() => {
		getBusinessData()
	}, []);

	return {
		data,
		status
	}
};

export default useBusiness;