import { useEffect, useState } from 'react';

import { getAbout } from '../utils/aboutService';

const useAbout = () => {
	const [about, setAbout] = useState(null);
	const [status, setStatus] = useState(null);

	const getAboutData = async () => {
		setStatus('loading');
		try {
			const res = await getAbout();
			setAbout(res.details)
		} catch (error) {
			setStatus('error');
		} finally {
			setStatus('success');
		}
	};

	useEffect(() => {
		getAboutData()
	}, []);

	return {
		about,
		status
	}
};

export default useAbout;