import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import DefaultLayout from '../layouts/DefaultLayout';
import Home from '../pages/Home';

const Router = () => (
  <BrowserRouter>
		<Routes>
			<Route element={<DefaultLayout />}>
				<Route exact path="/" element={<Home />}/>
			</Route>
		</Routes>
	</BrowserRouter>
);

export default Router;