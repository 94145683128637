import About from '../components/About';
import Hours from '../components/Hours';
import Employees from '../components/Employees';
import Price from '../components/Price';

const Home = () => {
	return (
		<>
			<Hours />
			<About />
			<Employees />
			<Price />
		</>
	);
};

export default Home;