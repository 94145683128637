import client from './client';

const employeeFields = `
	_id,
	bio,
	'image': image.asset->url,
	name,
	'slug': slug.current,
	specialist
`
export const getEmployee = async () => {
  const getEmployeeData = await client.fetch(`*[_type == "employee"]{${employeeFields}}`);
  return getEmployeeData;
};