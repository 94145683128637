import Router from './routes/Router';
import styled from 'styled-components';

import { Container } from '@mui/material';

const Content = styled.section`
	// background-image: linear-gradient(180deg, #f5f7fa 0%, #c3cfe2 100%);
	// background-repeat: no-repeat;
`;

const App = () => {
  return (
    <>
			<Content>
				<Container maxWidth='xl' className='container'>
					<Router />
				</Container>
			</Content>
    </>
  );
}

export default App;
