import { useEffect, useState } from 'react';

import { getHours } from '../utils/hoursService';

const useHours = () => {
	const [general, setGeneral] = useState(null);
	const [holidays, setHolidays] = useState(null);
	const [info, setInfo] = useState(null);
	const [title, setTitle] = useState(null);
	const [visible, setVisible] = useState(false);
	const [status, setStatus] = useState(null);

	const getHoursData = async () => {
		setStatus('loading');
		try {
			const res = await getHours();
			setGeneral(res.general);
			setHolidays(res.holidays);
			setInfo(res.info);
			setTitle(res.title);
			setVisible(res.holidays.visible)
		} catch (error) {
			setStatus('error');
		} finally {
			setStatus('success');
		}
	};

	useEffect(() => {
		getHoursData()
	}, []);

	return {
		general,
		holidays,
		info,
		status,
		title,
		visible
	}
};

export default useHours;