import client from './client';

const hoursFields = `
	title,
	general,
	info,
	holidays,
`
export const getHours = async () => {
  const getHoursData = await client.fetch(`*[_type == "hours"]{${hoursFields}}`);
  return getHoursData[0];
};