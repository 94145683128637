import client from './client';

const businessFields = `
	"logo": logo.asset->url,
	name,
	address,
	phone,
	emergency,
	mail
`
export const getBusiness = async () => {
  const getBusinessData = await client.fetch(`*[_type == "business"]{${businessFields}}`);
  return getBusinessData[0];
};