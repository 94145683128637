import BlockContent from '@sanity/block-content-to-react';
import { Alert, CircularProgress } from '@mui/material';

import useHours from '../hooks/useHours';
import Card from '../components/Card';
import InfoCard from '../components/InfoCard';


const Hours = () => {
	const { general, holidays, info, status, title, visible } = useHours();

	if (status === 'loading') return <CircularProgress />

	return (
		<>
			{visible &&
			<InfoCard>
				<Alert severity='info' sx={{borderRadius: 4}}>
					{holidays?.info.map((item) => (
						<p key={item._key}>
							<span>{item.children[0].text} </span>
						</p>
					))}
					{holidays?.dates.map((item) => (
						<p className='week' key={item._key}>
							<span className='holiday'>{item.day}: </span>
							<span className='time'>
								<span className='from'>{item.from}</span>
								{item.to &&
									<>
										<span>-</span>
										<span className='to'>{item.to}</span>
									</>
								}
							</span>
						</p>
					))}
				</Alert>
				</InfoCard>
			}
			<Card>
				<BlockContent blocks={title || []} />
				{general?.map((item) => (
					<p className='week' key={item._key}>
						<span className='day'>{item.day}: </span>
						<span className='time'>
							<span className='from'>{item.from}</span>
							{item.to &&
								<>
									<span>-</span>
									<span className='to'>{item.to}</span>
								</>
							}
						</span>
					</p>
				))}
				{info?.map((item) => (
					<p key={item._key}>
						<span className='day'>{item.children[0].text} </span>
					</p>
				))}
			</Card>
		</>
	);
};

export default Hours;