import styled from 'styled-components';

const Section = styled.section`
	border-radius: 16px;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	margin-top: 40px;
`;

const InfoCard = ({ children }) => {
	return (
		<>
			<Section>
				{children}
			</Section>
		</>
	);
};

export default InfoCard;