import styled from 'styled-components';
import { Avatar } from '@mui/material'

import useEmployee from '../hooks/useEmployee';
import Card from '../components/Card';

const EmployeeAvatar = styled.section`
	align-items: center;
	display: flex;
	flex-direction: column;
	width: 250px;
`;

const EmployeeList = styled.section`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	@media (max-width: 814px) {
    justify-content: center;
  }
`;

const EmptyAvatar = styled.div`
	width: 150px;
	height: 150px;
	background-color: #eee;
	border-radius: 125px;
	margin-right: 20px;
`;

const Name = styled.p`
	font-size: 18px;
	margin-bottom: 4px;
`;

const Specialist = styled.p`
	font-size: 12px;
	margin-top: 0
`;

const Employees = () => {
	const { employee, status } = useEmployee();

	return (
		<>
			<Card>
				<h3>Våre ansatte:</h3>
				<EmployeeList>
					{ status === 'loading' | 'error'
					? <>
							<EmptyAvatar />
							<EmptyAvatar />
							<EmptyAvatar />
						</>
					: employee?.map((item) => (
						<EmployeeAvatar  key={item._id}>
							<Avatar alt={item.name} src={item.image} sx={{ width: 150, height: 150 }}/>
							<Name>{item.name}</Name>
							<Specialist>{item.specialist}</Specialist>
						</EmployeeAvatar>
					))}
				</EmployeeList>
			</Card>
		</>
	);
};

export default Employees;